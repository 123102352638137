@import "~antd/dist/antd.css";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  // background: var(--background);
}

#__react-alert__ div div div {
  /* width: auto !important; */
  -webkit-box-flex: 3;
  -webkit-flex: 3;
  -ms-flex: 3;
  flex: 3;
  text-transform: initial !important;
  font: inherit !important;
  padding: 8px 10px;
}

:root {
  --white: #fff;
  --black: #000000;
  --blackText: #444444;

  --background: #f7f8fa;
  --secondary: #292623;

  --primary: #dc702a;

  --text: #bebdcc;
  --textGrayCard: #7c7c7c;
  --textGray: #adadad;

  --notification: #f10707;

  --lineGray: #d2d2d2;

  --headerClient: #00ff00;

  --delivery: #ebcd00;
  --collect: #28a745;
  --transfer: #8100d8;
  --warning: #f10707;
  --finish: #d5d5da;
  --traveling: #00ff00;
}

html {
  font-size: 115%;
  background: var(--gray-50);
  height: 100vh;
}

body {
  background: var(--gray-50);
}

@media (max-width: 1080px) {
  html {
    font-size: 93.75%;
  }
}

@media (max-width: 720px) {
  html {
    font-size: 87.5%;
  }
}

body,
input,
p,
textarea,
button,
label {
  font: 400 1rem Segoe UI, sans-serif;
  color: var(--blackText);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
  font-family: Segoe UI, sans-serif;
  color: var(--blackText);
}

h1 {
  font-size: 2rem;
}

h2 {
  font-size: 1.5rem;
}

button,
input {
  cursor: pointer;
}
