.container-romaneios {
  margin-top: 1.5rem;

  .filter-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2rem;
  }

  .block-select-driver {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .filter {
      color: var(--secondary);
      font-weight: 600;
      font-size: 1rem;
      margin-right: 1rem;

      span {
        margin-left: .5rem;
      }
    }
  }

  .titles {
    display: grid;
    grid-template-columns: 5fr 1fr 1fr 2fr;
    margin-top: 1rem;
    padding: 0 1rem;

    .blockTitles:first-child {
      width: 100%;
      display: grid;
      grid-template-columns: 25% 25% 25% 25%;
    }

    .blockTitles:last-child {
      width: 100%;
      justify-content: flex-end;
      padding-left: 12px;
    }

    .blockTitles {
      display: flex;
      justify-content: center;
      width: 100%;

      span {
        color: var(--secondary);
        font-weight: bold;
        font-size: 0.7rem;
      }
    }
  }

  .paginationContainer {
    display: flex;
    gap: 0.3rem;
    justify-content: flex-end;

    button {
      padding: 0.3rem;
      width: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    select {
      display: flex;
      justify-content: center;
      width: 50px;
    }
  }

  .block-checkbox {
    display: flex;
    justify-content: flex-end;
    width: 100%;

    .input-checkbox {
      display: flex;
      align-items: flex-start;
      margin: 0 12px;

      input {
        width: 1.3em;
        height: 1.3em;
        background-color: white;
        border-radius: 50%;
        vertical-align: middle;
        border: 1px solid #ddd;
        -webkit-appearance: none;
        outline: none;
        cursor: pointer;
        position: relative;
        margin-right: 6px;
      }

      img {
        width: 1.35em;
        height: 1.3em;
        position: absolute;
        cursor: pointer;
      }
    }
  }
}